import { IAtlasDatumRequest, IAtlasDatumResponse } from "./AtlasModels";

// Create Action Constants
export enum AtlasActionTypes {

  // Generic Data Request / Response
  REQUEST_DATA = "REQUEST_DATA",
  RESPONSE_DATA = "RESPONSE_DATA",

  REQUEST_DATUM = "REQUEST_DATUM",
  RESPONSE_DATUM = "RESPONSE_DATUM",

  // Handle Blocking Request
  REQUEST_BLOCKING_STARTED = "REQUEST_BLOCKING_STARTED",
  REQUEST_BLOCKING_FAILURE = "REQUEST_BLOCKING_FAILURE",

  // Handle Non-Blocking Request
  REQUEST_NONBLOCKING_STARTED = "REQUEST_NONBLOCKING_STARTED",
  REQUEST_NONBLOCKING_FAILURE = "REQUEST_NONBLOCKING_FAILURE",
}

export interface IRequestDataAction {
  type: AtlasActionTypes.REQUEST_DATA;
  payload: Array<IAtlasDatumRequest>;
}

export interface IResponseDataAction {
  type: AtlasActionTypes.RESPONSE_DATA;
  payload: Array<IAtlasDatumResponse>;
}

export interface IRequestDatumAction {
  type: AtlasActionTypes.REQUEST_DATUM;
  payload: Array<IAtlasDatumRequest>;
}

export interface IResponseDatumAction {
  type: AtlasActionTypes.RESPONSE_DATUM;
  payload: IAtlasDatumResponse;
}

export interface IRequestBlockingStartedAction {
  type: AtlasActionTypes.REQUEST_BLOCKING_STARTED;
  payload: IAtlasDatumRequest;
}

export interface IRequestNonBlockingStartedAction {
  type: AtlasActionTypes.REQUEST_NONBLOCKING_STARTED;
  payload: any;
}

export interface IRequestBlockingFailureAction {
  type: AtlasActionTypes.REQUEST_BLOCKING_FAILURE;
  payload: any;
}

export interface IRequestNonBlockingFailureAction {
  type: AtlasActionTypes.REQUEST_NONBLOCKING_FAILURE;
  payload: any;
}

export type AtlasActions =
  | IRequestDataAction
  | IResponseDataAction
  | IRequestDatumAction
  | IResponseDatumAction
  | IRequestBlockingStartedAction
  | IRequestBlockingFailureAction
  | IRequestNonBlockingStartedAction
  | IRequestNonBlockingFailureAction;
