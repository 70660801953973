const React = require('react');
const { Provider } = require('react-redux');
const { configureStore } = require('./src/redux/store');

const store = configureStore();
exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // if (typeof window.IntersectionObserver === `undefined`) {
  //   import(`intersection-observer`);
  //   console.log(`# IntersectionObserver is polyfilled!`);
  // }
};
exports.wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};
