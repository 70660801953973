import { Reducer } from "redux";
import { AtlasActions, AtlasActionTypes } from "./AtlasActions";
import {
  IAtlasAttributes,
  IAtlasDownloads,
  IAtlasGene,
  IAtlasLabels,
  IAtlasPoints,
  IAtlasState,
  IAtlasTable
} from "./AtlasModels";

const initialAtlasState: IAtlasState = {
  attributes: [],
  points: [],
  genes: [],
  labels: [],
  tables: [],
  downloads: [],
  jsons: [],
  csvs: []
};

export const atlasReducer: Reducer<IAtlasState, AtlasActions> = (
  state = initialAtlasState,
  action
) => {
  switch (action.type) {
    case AtlasActionTypes.RESPONSE_DATA:
      const genesArray: Array<IAtlasGene> = [];
      const pointsArray: Array<IAtlasPoints> = [];
      const attributesArray: Array<IAtlasAttributes> = [];
      const labelsArray: Array<IAtlasLabels> = [];
      const tablesArray: Array<IAtlasTable> = [];
      const downloadsArray: Array<IAtlasDownloads> = [];
      const jsonsArray: Array<any> = [];
      const csvsArray: Array<any> = [];

      action.payload.forEach((result) => {
        const index = result.request.index || 0;

        switch (result.request.type) {
          case "json":
            jsonsArray[index] = result.response;
            break;
          case "csv":
            csvsArray[index] = result.response;
            break;
          case "gene":
            genesArray[index] = result.response as IAtlasGene;
            break;
          case "points":
            pointsArray[index] = result.response as IAtlasPoints;
            break;
          case "attributes":
            attributesArray[index] = result.response as IAtlasAttributes;
            break;
          case "labels":
            labelsArray[index] = result.response as IAtlasLabels;
            break;
          case "table":
            tablesArray[index] = result.response as IAtlasTable;
            break;
          case "downloads":
            downloadsArray[index] = result.response as IAtlasDownloads;
            break;
        }
      });
      const rv = Object.assign({}, state, {
        genes: genesArray,
        points: pointsArray,
        attributes: attributesArray,
        labels: labelsArray,
        tables: tablesArray,
        downloads: downloadsArray,
        jsons: jsonsArray,
        csvs: csvsArray
      });
      return rv;

    default:
      return state;
  }
};
