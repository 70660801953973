import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { IAtlasState } from "../atlas/AtlasModels";
import { atlasReducer } from "../atlas/AtlasReducer";

// Create an interface for the application state
export interface IAppState {
  atlasState: IAtlasState;
}

// Create the root reducer
const rootReducer = combineReducers<IAppState>({
  atlasState: atlasReducer
});

// Create a configure store function of type `IAppState`
export const configureStore = (): Store<IAppState, any> => {
  return createStore(rootReducer, undefined, applyMiddleware(thunk));
};
